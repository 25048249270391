// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-[id]-js": () => import("./../../../src/pages/blog/[id].js" /* webpackChunkName: "component---src-pages-blog-[id]-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-coconstruccionleyes-js": () => import("./../../../src/pages/coconstruccionleyes.js" /* webpackChunkName: "component---src-pages-coconstruccionleyes-js" */),
  "component---src-pages-conocenos-js": () => import("./../../../src/pages/conocenos.js" /* webpackChunkName: "component---src-pages-conocenos-js" */),
  "component---src-pages-consultadigital-js": () => import("./../../../src/pages/consultadigital.js" /* webpackChunkName: "component---src-pages-consultadigital-js" */),
  "component---src-pages-datosenfuga-js": () => import("./../../../src/pages/datosenfuga.js" /* webpackChunkName: "component---src-pages-datosenfuga-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presupuestoparticipativo-js": () => import("./../../../src/pages/presupuestoparticipativo.js" /* webpackChunkName: "component---src-pages-presupuestoparticipativo-js" */),
  "component---src-pages-recursos-js": () => import("./../../../src/pages/recursos.js" /* webpackChunkName: "component---src-pages-recursos-js" */),
  "component---src-pages-seguimientometas-js": () => import("./../../../src/pages/seguimientometas.js" /* webpackChunkName: "component---src-pages-seguimientometas-js" */),
  "component---src-pages-transparencia-js": () => import("./../../../src/pages/transparencia.js" /* webpackChunkName: "component---src-pages-transparencia-js" */),
  "component---src-pages-votacionautoridades-js": () => import("./../../../src/pages/votacionautoridades.js" /* webpackChunkName: "component---src-pages-votacionautoridades-js" */)
}

